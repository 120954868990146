import history from '../../../../history';
import { Colors } from '../../../../themes/colors';
import { Table as TableComponent } from '../../../commonComponents/Table/Table';
import { TabViewContainer } from '../../../commonComponents/TabViewContainer';
import { style } from '../../../commonComponents/user-column';
import { CreateNewButton } from '../../DsoGroupDetails/components/OfficeAdmin/CreateNewButton';
import { TableHeader } from './components';
import { useOfficeAdminList } from './hooks/useOfficeAdminList';

export const OfficeAdminList = () => {
  const { data, filters, isLoading, total, totalPages, handleFilterChanged, handlePageChanged } =
    useOfficeAdminList();

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <TabViewContainer
        title="Office Staff"
        style={{
          marginInline: 'auto',
          width: '85%',
          paddingBottom: 20,
        }}
      >
        <TableHeader filters={filters} handleFilterChanged={handleFilterChanged} />
        <article
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: 20,
            justifyContent: 'flex-end',
            padding: 20,
            width: '100%',
          }}
        >
          <CreateNewButton
            className="btn btn-success"
            dsoId={undefined}
            buttonText="Create New Staff Member"
            modalTitle="Create New Staff Member Account"
          />
          <span
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: Colors.neutral_600,
            }}
          >
            Total | {total !== undefined ? Intl.NumberFormat().format(total) : ''}
          </span>
        </article>
        <TabViewContainer variant="card" style={{ margin: 0, padding: 20 }}>
          <TableComponent
            filter={filters}
            columns={[
              {
                id: 'firstName',
                selector: ({ firstName }) => firstName,
                name: 'First Name',
                style: style.tableText,
              },
              {
                id: 'lastName',
                selector: ({ lastName }) => lastName,
                name: 'Last Name',
                style: style.tableText,
              },
              {
                id: 'email',
                selector: ({ email }) => email,
                name: 'Email',
                style: style.tableText,
              },
              {
                id: 'phoneNumber',
                selector: ({ phoneNumber }) => phoneNumber,
                name: 'Phone Number',
                style: style.tableText,
              },
              {
                id: 'dsoName',
                selector: ({ dsoID }) => dsoID || '-',
                name: 'DSO Name',
                style: style.tableText,
              },
            ]}
            initialPage={filters.page}
            data={data}
            forceLoading={isLoading}
            noDataComponent="There are no Office Admins"
            isLoading={isLoading}
            totalPages={totalPages}
            paginationPerPage={filters.limit}
            paginationTotalRows={total}
            onPageChange={handlePageChanged}
            onRowClicked={(it) => history.push(`/staff/${it.id}`)}
            showColumnsToHide={false}
            showTotal={false}
          />
        </TabViewContainer>
      </TabViewContainer>
    </div>
  );
};
