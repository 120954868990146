import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';

import {
  fetchNotificationSettingsHYG,
  updateUserNotificationSettingsHYG,
} from '../../actions/userActions';
import { notificationTypeKey, notificationTypeMapHYG } from '../../constants';

const NotificationSettingsHYG = () => {
  const dispatch = useDispatch();

  const [currentSettings, setCurrentSettings] = useState({});
  const [currentNotifSettings, setCurrentNotifSettings] = useState([]);

  const jobSettings = useSelector((state) => state.users.currentUser?.jobSettingsHYG);
  const notifSettings = useSelector((state) => state.users.currentUser?.notificationSettingsHYG);

  useEffect(() => {
    dispatch(fetchNotificationSettingsHYG());
  }, [dispatch]);

  useEffect(() => {
    if (jobSettings) {
      setCurrentSettings(jobSettings);
    }
    if (notifSettings) {
      setCurrentNotifSettings(notifSettings);
    }
  }, [jobSettings, notifSettings]);

  const formChanged =
    JSON.stringify(jobSettings) !== JSON.stringify(currentSettings) ||
    JSON.stringify(notifSettings) !== JSON.stringify(currentNotifSettings);

  const renderSwitch = (key, checked, title, field) => (
    <div key={key} style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
      <ReactSwitch
        checked={checked}
        onChange={() => {
          if (field) {
            setCurrentNotifSettings(prev => prev.map((item) => {
                if (item.event === key) {
                  return {
                    ...item,
                    [field]: !checked
                  }

                } 
                return item
              }));
          } else {
            setCurrentSettings({ ...currentSettings, [key]: !checked });
          }
        }}
        onColor="#34C9EB"
        onHandleColor="#fff"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={25}
        width={50}
        className="react-switch"
        id="material-switch"
      />

      <h5
        className="global_font bold"
        style={{ color: '#303F46', margin: 0, textTransform: 'capitalize' }}
      >
        {title}
      </h5>
    </div>
  );

  const renderWorkingDaySection = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #EDEDED',
        padding: '20px 0px',
      }}
    >
      <div style={{ width: '50%', marginLeft: 20 }}>
        <div style={{ display: 'flex' }}>
          <h5
            className="global_font bold"
            style={{
              fontSize: 16,
              color: '#303F46',
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            Working Days (Push Notification)
          </h5>
        </div>

        <p className="global_font" style={{ color: '#435963' }}>
          Days for which Professional wishes to be notified about new shifts.
        </p>
      </div>

      <div
        style={{
          marginLeft: 120,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: 18,
        }}
      >
        {Object.entries(currentSettings).map(([settingKey, settingValue]) => {
          const notification = notificationTypeMapHYG.WORKING_DAYS.find(
            ({ key }) => key === settingKey,
          );
          if (notification) {
            return renderSwitch(settingKey, settingValue, notification.title);
          }
          return null;
        })}
      </div>
    </div>
  );

  const renderShiftTypeSection = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #EDEDED',
        padding: '20px 0px',
      }}
    >
      <div style={{ width: '50%', marginLeft: 20 }}>
        <div style={{ display: 'flex' }}>
          <h5
            className="global_font bold"
            style={{
              fontSize: 16,
              color: '#303F46',
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            Shift Types (Push Notification)
          </h5>
        </div>

        <p className="global_font" style={{ color: '#435963' }}>
          Shift types which Professional wishes to be notified about.
        </p>
      </div>

      <div
        style={{
          marginLeft: 120,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: 18,
        }}
      >
        {Object.entries(currentSettings).map(([settingKey, settingValue]) => {
          const notification = notificationTypeMapHYG.SHIFT_TYPES.find(
            ({ key }) => key === settingKey,
          );
          if (notification) {
            return renderSwitch(settingKey, settingValue, notification.title);
          }
          return null;
        })}
      </div>
    </div>
  );

  const renderNotifSection = (event) => {
    const fieldTarget = currentNotifSettings.find((item) => item.event === event);
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          borderBottom: '1px solid #EDEDED',
          padding: '20px 0px',
        }}
      >
        <div style={{ width: '50%', marginLeft: 20 }}>
          <div style={{ display: 'flex' }}>
            <h5
              className="global_font bold"
              style={{
                fontSize: 16,
                color: '#303F46',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              GoTu News & Product Updates
            </h5>
          </div>

          <p className="global_font" style={{ color: '#435963' }}>
            These are notifications to send you the latest news from GoTu, including product
            updates, events, and more.
          </p>
        </div>

        <div
          style={{
            marginLeft: 120,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            rowGap: 18,
          }}
        >
          {fieldTarget ? (
            <>
              {renderSwitch(event, fieldTarget?.email, 'Email', 'email')}
              {renderSwitch(event, fieldTarget?.text, 'SMS/Text', 'text')}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
      }}
    >
      <h1
        className="global_font f-dark"
        style={{
          fontSize: 30,
          marginTop: 45,
          marginLeft: 30,
          textAlign: 'center',
        }}
      >
        Notification Settings
      </h1>

      <div style={{ width: '100%', borderBottom: '1px solid #EDEDED' }}>
        <h5 className="global_font bold" style={{ fontSize: 16, color: '#3EB0CA', marginLeft: 20 }}>
          Notification Type
        </h5>
      </div>

      {renderWorkingDaySection()}
      {renderShiftTypeSection()}
      {renderNotifSection(notificationTypeKey.PROFESSIONAL_SETTING_ALLOW_MARKETING_OUTREACH)}

      <button
        type="button"
        className="btn btn-info mr-1 ml-1"
        style={{ width: 100, margin: '80px 0px 45px 0px' }}
        // eslint-disable-next-line max-len
        onClick={() => dispatch(updateUserNotificationSettingsHYG(currentSettings, currentNotifSettings))}
        disabled={!formChanged}
      >
        Save
      </button>
    </div>
  );
};

export default NotificationSettingsHYG;
