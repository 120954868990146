export const LineInfo = ({ title, children, text }) => (
  <div
    className="container"
    style={{
      width: '100%',
      borderBottom: '1px solid #efefef',
      padding: 5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <p className="col-6" style={{ fontWeight: '600', margin: 2 }}>
      {title}
    </p>

    {children ? (
      <div className="col-6" style={{ textAlign: 'right' }}>
        {children}
      </div>
    ) : (
      <p className="col-6" style={{ textAlign: 'right', margin: 2 }}>
        {text}
      </p>
    )}
  </div>
);
