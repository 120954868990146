import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Combobox } from '../../../../../commonComponents/Combobox/Combobox';
import { fetchDsosList } from '../../../../../../actions/dsosActions';

export const DsoFilter = ({ filters, handleFilterChanged, label = 'DSO:' }) => {
  const dispatch = useDispatch();

  const { data, filters: dsoOptionsFilters, total } = useSelector((state) => state.dsos.dsosList);

  const options = useMemo(
    () =>
      data.map((dso) => ({
        label: dso.name,
        value: dso.id,
      })),
    [data],
  );

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchDsosList({ page: 1 }));
    }
  }, [data, dispatch]);

  return (
    <Combobox
      hasReachMax={total === data.length}
      isAutoApply
      isPaged
      label={label}
      options={options}
      placeholder="All"
      values={options?.find((it) => it.value === filters.dsoId) || []}
      onChange={(values) => {
        handleFilterChanged('dsoId', values?.value);
      }}
      onNextPage={() => {
        dispatch(fetchDsosList({ page: dsoOptionsFilters.page + 1 }, true));
      }}
    />
  );
};
