import history from '../../../../../../../../../../history';
import { ChevronRightIcon } from '../../../../../../../../../../images/icons';
import { Card } from '../../../../../../../../../commonComponents/Card';
import { List } from '../../../../../../../../../commonComponents/CardList';
import { ListItem } from '../../../../../../../../../commonComponents/ListItem';
import { Pagination } from '../../../../../../../../../commonComponents/Table/Pagination';
import { OverviewHeader, SearchOffice } from './components';
import { useOverviewTabPanel } from './hooks/useOverviewTabPanel';

export const OverviewTabPanel = () => {
  const {
    filters,
    formOfficeName,
    isLoading,
    offices,
    totalOffices,
    handlePageChanges,
    handleSearchOffices,
  } = useOverviewTabPanel();

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SearchOffice value={formOfficeName} onChange={handleSearchOffices} />
      <OverviewHeader style={{ marginTop: 32 }} totalOffices={totalOffices} />
      <Card style={{ border: 'none' }}>
        <List
          emptyText="There are no offices available. If this staff member is part of a DSO, you must choose a DSO name first. If it’s part of a private practice, add an office to populate this list."
          data={offices}
          isLoading={isLoading}
          renderItem={({ item }) => (
            <ListItem
              TrailingComponent={() => (
                <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                  <button
                    type="button"
                    style={{
                      all: 'unset',
                      width: 24,
                      height: 24,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => history.push(`/user/dentist/${item.id}`)}
                  >
                    <ChevronRightIcon />
                  </button>
                </div>
              )}
              key={item.id}
            >
              <article style={{ paddingInline: 24, gap: 12, display: 'flex', width: '55%' }}>
                <span>&bull;</span> {item.name}
              </article>
              <article style={{ width: '35%' }}>{item.regionID || '-'}</article>
            </ListItem>
          )}
          style={{
            marginInline: 0,
          }}
        />
        <Pagination
          currentPage={filters.page}
          totalPages={Math.ceil(totalOffices / filters.limit)}
          onPageChange={handlePageChanges}
          style={{
            marginBlock: 0,
          }}
        />
      </Card>
    </section>
  );
};
