import { Spinner } from '../../../../../../../commonComponents/Spinner';
import { Table as TableComponent } from '../../../../../../../commonComponents/Table/Table';
import { TabViewContainer } from '../../../../../../../commonComponents/TabViewContainer';
import { useRateLimitHistory } from './hooks/useRateLimitHistory';

export const RateLimitHistory = () => {
  const { data, filters, isInitialLoading, isLoading, total, totalPages, handlePageChanged } =
    useRateLimitHistory();

  return (
    <TabViewContainer
      style={{
        margin: 0,
        minWidth: 'auto',
        paddingInline: 24,
      }}
      title="Rate Limit History"
      variant="card"
    >
      {isInitialLoading ? (
        <Spinner />
      ) : (
        <TableComponent
          columns={[
            {
              id: 'professionType',
              selector: ({ professionType }) => professionType,
              name: 'Profession Type',
              center: true,
              style: {
                fontSize: 14,
              },
            },
            {
              id: 'oldValue',
              selector: ({ oldValue }) => oldValue,
              name: 'Old Value',
              center: true,
              style: {
                fontSize: 14,
              },
            },
            {
              id: 'newValue',
              selector: ({ newValue }) => newValue,
              name: 'New Value',
              center: true,
              style: {
                fontSize: 14,
              },
            },
            {
              id: 'createdBy',
              selector: ({ createdBy }) => createdBy,
              name: 'Modified By',
              center: true,
              width: '248px',
              cell: ({ createdBy }) => (
                <button
                  type="button"
                  style={{
                    fontSize: 14,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    appearance: 'none',
                    background: 'none',
                    border: 'none',
                  }}
                >
                  {createdBy}
                </button>
              ),
            },
          ]}
          data={data}
          initialPage={filters.page}
          isLoading={isLoading}
          noDataComponent="There are no rate limit histories"
          paginationPerPage={filters.limit}
          paginationTotalRows={total}
          showColumnsToHide={false}
          showTotal={false}
          totalPages={totalPages}
          onPageChange={handlePageChanged}
        />
      )}
    </TabViewContainer>
  );
};
